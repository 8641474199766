import React from 'react'
import { Link } from 'gatsby'
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import notFound from "../images/404.svg"

const NotFound = () => {
    return (
        <Layout>
            <Head title=":( Sayfa bulunamadı" />
            <div className="row blog-index">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Böyle bir sayfa bulamadık. <span role="img" aria-label="emoji">😵</span></h1>
                <p>Yanlış bir yere geldin gibi gözüküyor, burası çıkmaz sokak. En iyisi sen <Link to="/">anasayfaya</Link> geri dön. <Link to="/">Yardım</Link> lazımsa bize yazabilirsin.</p>
                <h5><Link to="/contact" className="cta-blue-button">Bize Yaz</Link></h5>
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={notFound} alt="Not Found"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default NotFound